import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {GameStateModel} from "@/store/models/GameStateModel";
import {messaging} from "./../../rio-proto.js";

@Module({namespaced: true})
export default class GameStateModule extends VuexModule {
	private gameState: GameStateModel | null = null;

	@Action({commit: "setGameState"})
	async initialLoadGameState(gameState: GameStateModel): Promise<GameStateModel | null> {
		return gameState;
	}

	@Action({commit: "setGameState"})
	async set(gameState: GameStateModel): Promise<GameStateModel | null> {
		return gameState;
	}

	@Mutation
	setGameState(gameState: GameStateModel | null) {
		console.log(gameState);
		this.gameState = gameState;
	}

	get currentState() {
		if (this.gameState?.container.gameStatusUpdate) {
			return this.gameState.container.gameStatusUpdate
		}
		return null;
	}
	
	get connectionError() {
		if (this.gameState?.connectionError) {
			return this.gameState.connectionError
		}
		return "";
	}

	get playerStatus(): messaging.IPlayerUpdate {
		if (this.gameState?.container.gameStatusUpdate?.playerStatus) {
			return this.gameState?.container.gameStatusUpdate?.playerStatus;
		}
		return messaging.PlayerUpdate.create();
	}
	
	get highlightedPlayers(): string[]{
		if (this.gameState?.container.gameStatusUpdate?.highlightedPlayers) {
			return this.gameState?.container.gameStatusUpdate?.highlightedPlayers;
		}
		return [];
	}

	get gameTitle(): string {
		if (this.gameState?.container.gameStatusUpdate?.gameTitle) {
			return this.gameState?.container.gameStatusUpdate.gameTitle;
		}
		return "...";
	}

	get gameStatus(): messaging.GameStatus {
		if (this.gameState?.container.gameStatusUpdate?.gameStatus) {
			return this.gameState?.container.gameStatusUpdate.gameStatus;
		}
		return messaging.GameStatus.NEW;
	}

	get gameTopic(): string {
		if (this.gameState?.container.gameStatusUpdate?.gameTopic) {
			return this.gameState?.container.gameStatusUpdate.gameTopic;
		}
		return "";
	}
	
	get gameTopicLink(): string {
		if (this.gameState?.container.gameStatusUpdate?.gameTopicLink) {
			return this.gameState?.container.gameStatusUpdate.gameTopicLink;
		}
		return "";
	}

	get cardDeck(): messaging.CardDeckType {
		if (this.gameState?.container.gameStatusUpdate?.cardDeck) {
			return this.gameState?.container.gameStatusUpdate.cardDeck;
		}
		return messaging.CardDeckType.DEFAULT;
	}

	get isUnanimousVote(): boolean {
		if (this.gameState?.container.gameStatusUpdate?.unanimousVote) {
			return this.gameState?.container.gameStatusUpdate.unanimousVote;
		}
		return false;
	}
	
	get majorityVotedCard(): messaging.CardDeckCards {
		if (this.gameState?.container.gameStatusUpdate?.majorityVotedCard) {
			return this.gameState?.container.gameStatusUpdate.majorityVotedCard;
		}
		return messaging.CardDeckCards.SPECIAL_NOMAJORITY;
	}
}

