import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import IndexHeader from '../components/IndexHeader.vue'
import Footer from '../components/Footer.vue'
import Index from '../views/Index.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		components: {
			header: IndexHeader,
			default: Index,
			footer: Footer,
		}

	},
	{
		path: "/host/:gameID",
		name: "Host",
		props: route => ({
			...route.params
		}),
		component: () => import(/* webpackChunkName: "host" */ '../views/Host.vue')
	},
	{
		path: "/join/:gameID",
		name: "Join",
		props: true,
		component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue')
	},
	{
		path: "/j/:gameID",
		name: "HomeJoin",
		components: {
			header: IndexHeader,
			default: Index,
		}
	},
	{
		path: "/imprint",
		name: "Imprint",
		props: true,
		components: {
			header: IndexHeader,
			default: Imprint,
			footer: Footer,
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		props: true,
		components: {
			header: IndexHeader,
			default: Privacy,
			footer: Footer,
		}
	},
]

const router = new VueRouter({
	routes
})

export default router
