







































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { getGameStats, GameStats } from "@/rest/Game";

@Component({
  components: {},
})
export default class IndexHeader extends Vue {
  @Prop() private msg!: string;

  private showMobileMenu = false;
  private gameStats: GameStats = new GameStats(0, 0);

  created() {
    let stats = getGameStats();

    try {
      stats.then((s) => {
        this.gameStats = s;
      });
    } catch (err) {
      console.log(err);
    }
  }
}
