



























































































































import { Component, Prop, Vue } from "vue-property-decorator";

import SelectMenu from "@/components/SelectMenu.vue";
import IndexLogos from "@/components/IndexLogos.vue";
import Notification from "@/components/Notification.vue";
import { hostGame } from "@/rest/Game";
import { messaging } from "./../rio-proto.js";
import { getToken } from "@/rest/Game";
import { RestError } from "@/rest/Game";

@Component({
  components: {
    SelectMenu,
    IndexLogos,
    Notification,
  },
})
export default class IndexHero extends Vue {
  $refs!: {
    playerNameInput: HTMLInputElement;
  };

  private maintenanceMode = false;
  private notification = { title: "", description: "" };
  private gameID = this.$route.params.gameID;
  private mainAction = "";
  private playerName = "";
  private showMobileMenu = false;
  private mainConnectText = "";
  private isSessionID = false;
  private isValid = false;
  private isLoading = false;
  private displayError = "";
  private cardDecks: { [key: string]: messaging.CardDeckType } = {
    "Default Deck": messaging.CardDeckType.DEFAULT,
    "T-Shirt Sizes Deck": messaging.CardDeckType.TSHIRT,
  };
  private selectedCardDeckText = "Default Deck";
  private selectedCardDeck = messaging.CardDeckType.DEFAULT;

  private ACTION_JOIN = "JOIN";
  private ACTION_INVALID = "INVALID";
  private ACTION_HOST = "HOST";
  private ACTION_NONE = "NONE";

  mounted() {
    if (this.gameID !== undefined) {
      this.mainConnectText = this.gameID;
      this.validateConnectionBox();
      this.$nextTick(() => this.$refs.playerNameInput.focus());
    }
  }

  created() {
    if (this.$route.params.sID) {
      this.mainConnectText = this.$route.params.sID;
    }

    this.ACTION_JOIN = "JOIN";
    this.ACTION_INVALID = "INVALID";
    this.ACTION_HOST = "HOST";
    this.ACTION_NONE = "NONE";
    this.validateConnectionBox();
  }

  get mainButtonText(): string {
    switch (this.mainAction) {
      case this.ACTION_HOST:
        return "Host a new Session";
      case this.ACTION_JOIN:
        return "Join Session";
      case this.ACTION_INVALID:
        return "Invalid SessionID";

      default:
        return "Waiting for Input";
    }
  }

  get cardDeckText(): string {
    switch (this.selectedCardDeck) {
      case messaging.CardDeckType.DEFAULT:
        return "Default Deck";
      case messaging.CardDeckType.TSHIRT:
        return "T-Shirt Sizes Deck";

      default:
        return "Default Deck";
    }
  }

  validateConnectionBox(): void {
    var sid: RegExp = /^sid-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    var sidPrefix: RegExp = /^sid-.*/;

    var isSIDPrefix = sidPrefix.test(this.mainConnectText);
    this.isSessionID = sid.test(this.mainConnectText);
    this.displayError = "";

    if (isSIDPrefix && !this.isSessionID) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }

    if (this.mainConnectText === "") {
      this.mainAction = this.ACTION_NONE;
      this.isValid = false;
      return;
    }

    if (this.isValid && this.isSessionID) {
      this.mainAction = this.ACTION_JOIN;
    } else if (this.isValid) {
      this.mainAction = this.ACTION_HOST;
    } else {
      this.mainAction = this.ACTION_INVALID;
      this.isValid = false;
    }
  }

  performAction(): void {
    switch (this.mainAction) {
      case this.ACTION_HOST:
        this.hostGame();
        return;
      case this.ACTION_JOIN:
        this.joinGame();
        return;
    }
  }

  onCardDeckSelect(value: string): void {
    this.selectedCardDeckText = value;
    this.selectedCardDeck = this.cardDecks[value];
  }

  hostGame() {
    const resp = hostGame(this.mainConnectText, this.selectedCardDeck);
    let t = this;
    resp
      .then((c) => {
        console.log(c);
        this.$router.push({ name: "Host", params: { gameID: c.gameID } });
      })
      .catch(function (err) {
        t.logAndShowError(err);
      });
  }

  joinGame(): void {
    const resp = getToken(this.mainConnectText, this.playerName);
    let t = this;

    resp
      .then((c) => {
        this.$router.push(
          "/join/" + this.mainConnectText + "?playerName=" + this.playerName
        );
      })
      .catch(function (err) {
        t.logAndShowError(err);
      });
  }

  logAndShowError(err: RestError): void {
    this.notification.title = err.title;
    this.notification.description = err.message;
  }
}
