import { messaging } from "./../../rio-proto.js";

export const ConnectionErrorNoSessionCookie = "session cookie not found"

export class GameStateModel {
	constructor(
		public container: messaging.Container, 
		public connectionError: string
	) {
		this.container = container
	}
}
