







import { Component, Vue } from "vue-property-decorator";
import IndexHero from "@/components/IndexHero.vue"; // @ is an alias to /src
import Features from "@/components/Features.vue"; // @ is an alias to /src

@Component({
  components: {
    IndexHero,
    Features,
  },
})
export default class Home extends Vue {}
