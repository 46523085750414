import Vue from 'vue'
import Vuex from 'vuex'
import {GameStateModel} from './models/GameStateModel'
import GameStateModule from './modules/GameStateModule'
import { messaging } from "./../rio-proto.js";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		gameState: new GameStateModel(new messaging.Container(), "")
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		gameStateModule: GameStateModule,
	}
})
