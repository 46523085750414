















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class IndexLogos extends Vue {
  @Prop() private msg!: string;
}
