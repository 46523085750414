
















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Notification extends Vue {
  @Prop()
  private title!: string;
  @Prop()
  private description!: string;
  @Prop()
  private visible!: boolean;

  mounted() {}

  onClose(): void {
    this.$emit("close", true);
  }
}
