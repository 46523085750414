/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const messaging = $root.messaging = (() => {

    /**
     * Namespace messaging.
     * @exports messaging
     * @namespace
     */
    const messaging = {};

    messaging.Connection = (function() {

        /**
         * Properties of a Connection.
         * @memberof messaging
         * @interface IConnection
         * @property {boolean|null} [hosting] Connection hosting
         * @property {messaging.Connection.State|null} [state] Connection state
         */

        /**
         * Constructs a new Connection.
         * @memberof messaging
         * @classdesc Represents a Connection.
         * @implements IConnection
         * @constructor
         * @param {messaging.IConnection=} [properties] Properties to set
         */
        function Connection(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Connection hosting.
         * @member {boolean} hosting
         * @memberof messaging.Connection
         * @instance
         */
        Connection.prototype.hosting = false;

        /**
         * Connection state.
         * @member {messaging.Connection.State} state
         * @memberof messaging.Connection
         * @instance
         */
        Connection.prototype.state = 0;

        /**
         * Creates a new Connection instance using the specified properties.
         * @function create
         * @memberof messaging.Connection
         * @static
         * @param {messaging.IConnection=} [properties] Properties to set
         * @returns {messaging.Connection} Connection instance
         */
        Connection.create = function create(properties) {
            return new Connection(properties);
        };

        /**
         * Encodes the specified Connection message. Does not implicitly {@link messaging.Connection.verify|verify} messages.
         * @function encode
         * @memberof messaging.Connection
         * @static
         * @param {messaging.IConnection} message Connection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Connection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hosting != null && Object.hasOwnProperty.call(message, "hosting"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hosting);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified Connection message, length delimited. Does not implicitly {@link messaging.Connection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.Connection
         * @static
         * @param {messaging.IConnection} message Connection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Connection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Connection message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.Connection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.Connection} Connection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Connection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.Connection();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.hosting = reader.bool();
                    break;
                case 2:
                    message.state = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Connection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.Connection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.Connection} Connection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Connection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Connection message.
         * @function verify
         * @memberof messaging.Connection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Connection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hosting != null && message.hasOwnProperty("hosting"))
                if (typeof message.hosting !== "boolean")
                    return "hosting: boolean expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a Connection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.Connection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.Connection} Connection
         */
        Connection.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.Connection)
                return object;
            let message = new $root.messaging.Connection();
            if (object.hosting != null)
                message.hosting = Boolean(object.hosting);
            switch (object.state) {
            case "CONNECTION":
            case 0:
                message.state = 0;
                break;
            case "OPEN":
            case 1:
                message.state = 1;
                break;
            case "INIT":
            case 2:
                message.state = 2;
                break;
            case "ESTABLISHED":
            case 3:
                message.state = 3;
                break;
            case "DISCONNECTED":
            case 4:
                message.state = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Connection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.Connection
         * @static
         * @param {messaging.Connection} message Connection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Connection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hosting = false;
                object.state = options.enums === String ? "CONNECTION" : 0;
            }
            if (message.hosting != null && message.hasOwnProperty("hosting"))
                object.hosting = message.hosting;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.messaging.Connection.State[message.state] : message.state;
            return object;
        };

        /**
         * Converts this Connection to JSON.
         * @function toJSON
         * @memberof messaging.Connection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Connection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * State enum.
         * @name messaging.Connection.State
         * @enum {number}
         * @property {number} CONNECTION=0 CONNECTION value
         * @property {number} OPEN=1 OPEN value
         * @property {number} INIT=2 INIT value
         * @property {number} ESTABLISHED=3 ESTABLISHED value
         * @property {number} DISCONNECTED=4 DISCONNECTED value
         */
        Connection.State = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CONNECTION"] = 0;
            values[valuesById[1] = "OPEN"] = 1;
            values[valuesById[2] = "INIT"] = 2;
            values[valuesById[3] = "ESTABLISHED"] = 3;
            values[valuesById[4] = "DISCONNECTED"] = 4;
            return values;
        })();

        return Connection;
    })();

    /**
     * SpecialUserID enum.
     * @name messaging.SpecialUserID
     * @enum {number}
     * @property {number} HOST=0 HOST value
     */
    messaging.SpecialUserID = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "HOST"] = 0;
        return values;
    })();

    /**
     * GameStatus enum.
     * @name messaging.GameStatus
     * @enum {number}
     * @property {number} NEW=0 NEW value
     * @property {number} RUNNING=1 RUNNING value
     * @property {number} FINISHED=2 FINISHED value
     */
    messaging.GameStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NEW"] = 0;
        values[valuesById[1] = "RUNNING"] = 1;
        values[valuesById[2] = "FINISHED"] = 2;
        return values;
    })();

    messaging.GameStatusUpdate = (function() {

        /**
         * Properties of a GameStatusUpdate.
         * @memberof messaging
         * @interface IGameStatusUpdate
         * @property {Object.<string,messaging.CardDeckCards>|null} [playerStatus] GameStatusUpdate playerStatus
         * @property {Array.<string>|null} [highlightedPlayers] GameStatusUpdate highlightedPlayers
         * @property {Array.<string>|null} [majorityJoinedPlayers] GameStatusUpdate majorityJoinedPlayers
         * @property {messaging.CardDeckCards|null} [majorityVotedCard] GameStatusUpdate majorityVotedCard
         * @property {boolean|null} [unanimousVote] GameStatusUpdate unanimousVote
         * @property {Array.<string>|null} [hosts] GameStatusUpdate hosts
         * @property {messaging.GameStatus|null} [gameStatus] GameStatusUpdate gameStatus
         * @property {string|null} [gameTitle] GameStatusUpdate gameTitle
         * @property {string|null} [gameDeck] GameStatusUpdate gameDeck
         * @property {string|null} [gameTopic] GameStatusUpdate gameTopic
         * @property {number|null} [gameRound] GameStatusUpdate gameRound
         * @property {messaging.CardDeckType|null} [cardDeck] GameStatusUpdate cardDeck
         * @property {string|null} [gameTopicLink] GameStatusUpdate gameTopicLink
         */

        /**
         * Constructs a new GameStatusUpdate.
         * @memberof messaging
         * @classdesc Represents a GameStatusUpdate.
         * @implements IGameStatusUpdate
         * @constructor
         * @param {messaging.IGameStatusUpdate=} [properties] Properties to set
         */
        function GameStatusUpdate(properties) {
            this.playerStatus = {};
            this.highlightedPlayers = [];
            this.majorityJoinedPlayers = [];
            this.hosts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameStatusUpdate playerStatus.
         * @member {Object.<string,messaging.CardDeckCards>} playerStatus
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.playerStatus = $util.emptyObject;

        /**
         * GameStatusUpdate highlightedPlayers.
         * @member {Array.<string>} highlightedPlayers
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.highlightedPlayers = $util.emptyArray;

        /**
         * GameStatusUpdate majorityJoinedPlayers.
         * @member {Array.<string>} majorityJoinedPlayers
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.majorityJoinedPlayers = $util.emptyArray;

        /**
         * GameStatusUpdate majorityVotedCard.
         * @member {messaging.CardDeckCards} majorityVotedCard
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.majorityVotedCard = 0;

        /**
         * GameStatusUpdate unanimousVote.
         * @member {boolean} unanimousVote
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.unanimousVote = false;

        /**
         * GameStatusUpdate hosts.
         * @member {Array.<string>} hosts
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.hosts = $util.emptyArray;

        /**
         * GameStatusUpdate gameStatus.
         * @member {messaging.GameStatus} gameStatus
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.gameStatus = 0;

        /**
         * GameStatusUpdate gameTitle.
         * @member {string} gameTitle
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.gameTitle = "";

        /**
         * GameStatusUpdate gameDeck.
         * @member {string} gameDeck
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.gameDeck = "";

        /**
         * GameStatusUpdate gameTopic.
         * @member {string} gameTopic
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.gameTopic = "";

        /**
         * GameStatusUpdate gameRound.
         * @member {number} gameRound
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.gameRound = 0;

        /**
         * GameStatusUpdate cardDeck.
         * @member {messaging.CardDeckType} cardDeck
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.cardDeck = 0;

        /**
         * GameStatusUpdate gameTopicLink.
         * @member {string} gameTopicLink
         * @memberof messaging.GameStatusUpdate
         * @instance
         */
        GameStatusUpdate.prototype.gameTopicLink = "";

        /**
         * Creates a new GameStatusUpdate instance using the specified properties.
         * @function create
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {messaging.IGameStatusUpdate=} [properties] Properties to set
         * @returns {messaging.GameStatusUpdate} GameStatusUpdate instance
         */
        GameStatusUpdate.create = function create(properties) {
            return new GameStatusUpdate(properties);
        };

        /**
         * Encodes the specified GameStatusUpdate message. Does not implicitly {@link messaging.GameStatusUpdate.verify|verify} messages.
         * @function encode
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {messaging.IGameStatusUpdate} message GameStatusUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStatusUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playerStatus != null && Object.hasOwnProperty.call(message, "playerStatus"))
                for (let keys = Object.keys(message.playerStatus), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.playerStatus[keys[i]]).ldelim();
            if (message.highlightedPlayers != null && message.highlightedPlayers.length)
                for (let i = 0; i < message.highlightedPlayers.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.highlightedPlayers[i]);
            if (message.majorityJoinedPlayers != null && message.majorityJoinedPlayers.length)
                for (let i = 0; i < message.majorityJoinedPlayers.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.majorityJoinedPlayers[i]);
            if (message.majorityVotedCard != null && Object.hasOwnProperty.call(message, "majorityVotedCard"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.majorityVotedCard);
            if (message.unanimousVote != null && Object.hasOwnProperty.call(message, "unanimousVote"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.unanimousVote);
            if (message.hosts != null && message.hosts.length)
                for (let i = 0; i < message.hosts.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.hosts[i]);
            if (message.gameStatus != null && Object.hasOwnProperty.call(message, "gameStatus"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.gameStatus);
            if (message.gameTitle != null && Object.hasOwnProperty.call(message, "gameTitle"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.gameTitle);
            if (message.gameDeck != null && Object.hasOwnProperty.call(message, "gameDeck"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gameDeck);
            if (message.gameTopic != null && Object.hasOwnProperty.call(message, "gameTopic"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.gameTopic);
            if (message.gameRound != null && Object.hasOwnProperty.call(message, "gameRound"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.gameRound);
            if (message.cardDeck != null && Object.hasOwnProperty.call(message, "cardDeck"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.cardDeck);
            if (message.gameTopicLink != null && Object.hasOwnProperty.call(message, "gameTopicLink"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.gameTopicLink);
            return writer;
        };

        /**
         * Encodes the specified GameStatusUpdate message, length delimited. Does not implicitly {@link messaging.GameStatusUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {messaging.IGameStatusUpdate} message GameStatusUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStatusUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameStatusUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.GameStatusUpdate} GameStatusUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStatusUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.GameStatusUpdate(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (message.playerStatus === $util.emptyObject)
                        message.playerStatus = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = 0;
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.int32();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.playerStatus[key] = value;
                    break;
                case 2:
                    if (!(message.highlightedPlayers && message.highlightedPlayers.length))
                        message.highlightedPlayers = [];
                    message.highlightedPlayers.push(reader.string());
                    break;
                case 3:
                    if (!(message.majorityJoinedPlayers && message.majorityJoinedPlayers.length))
                        message.majorityJoinedPlayers = [];
                    message.majorityJoinedPlayers.push(reader.string());
                    break;
                case 4:
                    message.majorityVotedCard = reader.int32();
                    break;
                case 5:
                    message.unanimousVote = reader.bool();
                    break;
                case 6:
                    if (!(message.hosts && message.hosts.length))
                        message.hosts = [];
                    message.hosts.push(reader.string());
                    break;
                case 7:
                    message.gameStatus = reader.int32();
                    break;
                case 8:
                    message.gameTitle = reader.string();
                    break;
                case 9:
                    message.gameDeck = reader.string();
                    break;
                case 10:
                    message.gameTopic = reader.string();
                    break;
                case 11:
                    message.gameRound = reader.int32();
                    break;
                case 12:
                    message.cardDeck = reader.int32();
                    break;
                case 13:
                    message.gameTopicLink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameStatusUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.GameStatusUpdate} GameStatusUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStatusUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameStatusUpdate message.
         * @function verify
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameStatusUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playerStatus != null && message.hasOwnProperty("playerStatus")) {
                if (!$util.isObject(message.playerStatus))
                    return "playerStatus: object expected";
                let key = Object.keys(message.playerStatus);
                for (let i = 0; i < key.length; ++i)
                    switch (message.playerStatus[key[i]]) {
                    default:
                        return "playerStatus: enum value{k:string} expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
            }
            if (message.highlightedPlayers != null && message.hasOwnProperty("highlightedPlayers")) {
                if (!Array.isArray(message.highlightedPlayers))
                    return "highlightedPlayers: array expected";
                for (let i = 0; i < message.highlightedPlayers.length; ++i)
                    if (!$util.isString(message.highlightedPlayers[i]))
                        return "highlightedPlayers: string[] expected";
            }
            if (message.majorityJoinedPlayers != null && message.hasOwnProperty("majorityJoinedPlayers")) {
                if (!Array.isArray(message.majorityJoinedPlayers))
                    return "majorityJoinedPlayers: array expected";
                for (let i = 0; i < message.majorityJoinedPlayers.length; ++i)
                    if (!$util.isString(message.majorityJoinedPlayers[i]))
                        return "majorityJoinedPlayers: string[] expected";
            }
            if (message.majorityVotedCard != null && message.hasOwnProperty("majorityVotedCard"))
                switch (message.majorityVotedCard) {
                default:
                    return "majorityVotedCard: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    break;
                }
            if (message.unanimousVote != null && message.hasOwnProperty("unanimousVote"))
                if (typeof message.unanimousVote !== "boolean")
                    return "unanimousVote: boolean expected";
            if (message.hosts != null && message.hasOwnProperty("hosts")) {
                if (!Array.isArray(message.hosts))
                    return "hosts: array expected";
                for (let i = 0; i < message.hosts.length; ++i)
                    if (!$util.isString(message.hosts[i]))
                        return "hosts: string[] expected";
            }
            if (message.gameStatus != null && message.hasOwnProperty("gameStatus"))
                switch (message.gameStatus) {
                default:
                    return "gameStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.gameTitle != null && message.hasOwnProperty("gameTitle"))
                if (!$util.isString(message.gameTitle))
                    return "gameTitle: string expected";
            if (message.gameDeck != null && message.hasOwnProperty("gameDeck"))
                if (!$util.isString(message.gameDeck))
                    return "gameDeck: string expected";
            if (message.gameTopic != null && message.hasOwnProperty("gameTopic"))
                if (!$util.isString(message.gameTopic))
                    return "gameTopic: string expected";
            if (message.gameRound != null && message.hasOwnProperty("gameRound"))
                if (!$util.isInteger(message.gameRound))
                    return "gameRound: integer expected";
            if (message.cardDeck != null && message.hasOwnProperty("cardDeck"))
                switch (message.cardDeck) {
                default:
                    return "cardDeck: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.gameTopicLink != null && message.hasOwnProperty("gameTopicLink"))
                if (!$util.isString(message.gameTopicLink))
                    return "gameTopicLink: string expected";
            return null;
        };

        /**
         * Creates a GameStatusUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.GameStatusUpdate} GameStatusUpdate
         */
        GameStatusUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.GameStatusUpdate)
                return object;
            let message = new $root.messaging.GameStatusUpdate();
            if (object.playerStatus) {
                if (typeof object.playerStatus !== "object")
                    throw TypeError(".messaging.GameStatusUpdate.playerStatus: object expected");
                message.playerStatus = {};
                for (let keys = Object.keys(object.playerStatus), i = 0; i < keys.length; ++i)
                    switch (object.playerStatus[keys[i]]) {
                    case "SPECIAL_NOTCHOSEN":
                    case 0:
                        message.playerStatus[keys[i]] = 0;
                        break;
                    case "SPECIAL_CHOSEN":
                    case 1:
                        message.playerStatus[keys[i]] = 1;
                        break;
                    case "SPECIAL_JOINMAJORITY":
                    case 2:
                        message.playerStatus[keys[i]] = 2;
                        break;
                    case "SPECIAL_NOMAJORITY":
                    case 3:
                        message.playerStatus[keys[i]] = 3;
                        break;
                    case "DEFAULT_ZERO":
                    case 4:
                        message.playerStatus[keys[i]] = 4;
                        break;
                    case "DEFAULT_PFIVE":
                    case 5:
                        message.playerStatus[keys[i]] = 5;
                        break;
                    case "DEFAULT_ONE":
                    case 6:
                        message.playerStatus[keys[i]] = 6;
                        break;
                    case "DEFAULT_TWO":
                    case 7:
                        message.playerStatus[keys[i]] = 7;
                        break;
                    case "DEFAULT_THREE":
                    case 8:
                        message.playerStatus[keys[i]] = 8;
                        break;
                    case "DEFAULT_FIVE":
                    case 9:
                        message.playerStatus[keys[i]] = 9;
                        break;
                    case "DEFAULT_EIGHT":
                    case 10:
                        message.playerStatus[keys[i]] = 10;
                        break;
                    case "DEFAULT_THIRTEEN":
                    case 11:
                        message.playerStatus[keys[i]] = 11;
                        break;
                    case "DEFAULT_TWENTY":
                    case 12:
                        message.playerStatus[keys[i]] = 12;
                        break;
                    case "DEFAULT_FOURTY":
                    case 13:
                        message.playerStatus[keys[i]] = 13;
                        break;
                    case "DEFAULT_HUNDRED":
                    case 14:
                        message.playerStatus[keys[i]] = 14;
                        break;
                    case "TSHIRT_S":
                    case 15:
                        message.playerStatus[keys[i]] = 15;
                        break;
                    case "TSHIRT_M":
                    case 16:
                        message.playerStatus[keys[i]] = 16;
                        break;
                    case "TSHIRT_L":
                    case 17:
                        message.playerStatus[keys[i]] = 17;
                        break;
                    case "TSHIRT_XL":
                    case 18:
                        message.playerStatus[keys[i]] = 18;
                        break;
                    }
            }
            if (object.highlightedPlayers) {
                if (!Array.isArray(object.highlightedPlayers))
                    throw TypeError(".messaging.GameStatusUpdate.highlightedPlayers: array expected");
                message.highlightedPlayers = [];
                for (let i = 0; i < object.highlightedPlayers.length; ++i)
                    message.highlightedPlayers[i] = String(object.highlightedPlayers[i]);
            }
            if (object.majorityJoinedPlayers) {
                if (!Array.isArray(object.majorityJoinedPlayers))
                    throw TypeError(".messaging.GameStatusUpdate.majorityJoinedPlayers: array expected");
                message.majorityJoinedPlayers = [];
                for (let i = 0; i < object.majorityJoinedPlayers.length; ++i)
                    message.majorityJoinedPlayers[i] = String(object.majorityJoinedPlayers[i]);
            }
            switch (object.majorityVotedCard) {
            case "SPECIAL_NOTCHOSEN":
            case 0:
                message.majorityVotedCard = 0;
                break;
            case "SPECIAL_CHOSEN":
            case 1:
                message.majorityVotedCard = 1;
                break;
            case "SPECIAL_JOINMAJORITY":
            case 2:
                message.majorityVotedCard = 2;
                break;
            case "SPECIAL_NOMAJORITY":
            case 3:
                message.majorityVotedCard = 3;
                break;
            case "DEFAULT_ZERO":
            case 4:
                message.majorityVotedCard = 4;
                break;
            case "DEFAULT_PFIVE":
            case 5:
                message.majorityVotedCard = 5;
                break;
            case "DEFAULT_ONE":
            case 6:
                message.majorityVotedCard = 6;
                break;
            case "DEFAULT_TWO":
            case 7:
                message.majorityVotedCard = 7;
                break;
            case "DEFAULT_THREE":
            case 8:
                message.majorityVotedCard = 8;
                break;
            case "DEFAULT_FIVE":
            case 9:
                message.majorityVotedCard = 9;
                break;
            case "DEFAULT_EIGHT":
            case 10:
                message.majorityVotedCard = 10;
                break;
            case "DEFAULT_THIRTEEN":
            case 11:
                message.majorityVotedCard = 11;
                break;
            case "DEFAULT_TWENTY":
            case 12:
                message.majorityVotedCard = 12;
                break;
            case "DEFAULT_FOURTY":
            case 13:
                message.majorityVotedCard = 13;
                break;
            case "DEFAULT_HUNDRED":
            case 14:
                message.majorityVotedCard = 14;
                break;
            case "TSHIRT_S":
            case 15:
                message.majorityVotedCard = 15;
                break;
            case "TSHIRT_M":
            case 16:
                message.majorityVotedCard = 16;
                break;
            case "TSHIRT_L":
            case 17:
                message.majorityVotedCard = 17;
                break;
            case "TSHIRT_XL":
            case 18:
                message.majorityVotedCard = 18;
                break;
            }
            if (object.unanimousVote != null)
                message.unanimousVote = Boolean(object.unanimousVote);
            if (object.hosts) {
                if (!Array.isArray(object.hosts))
                    throw TypeError(".messaging.GameStatusUpdate.hosts: array expected");
                message.hosts = [];
                for (let i = 0; i < object.hosts.length; ++i)
                    message.hosts[i] = String(object.hosts[i]);
            }
            switch (object.gameStatus) {
            case "NEW":
            case 0:
                message.gameStatus = 0;
                break;
            case "RUNNING":
            case 1:
                message.gameStatus = 1;
                break;
            case "FINISHED":
            case 2:
                message.gameStatus = 2;
                break;
            }
            if (object.gameTitle != null)
                message.gameTitle = String(object.gameTitle);
            if (object.gameDeck != null)
                message.gameDeck = String(object.gameDeck);
            if (object.gameTopic != null)
                message.gameTopic = String(object.gameTopic);
            if (object.gameRound != null)
                message.gameRound = object.gameRound | 0;
            switch (object.cardDeck) {
            case "DEFAULT":
            case 0:
                message.cardDeck = 0;
                break;
            case "TSHIRT":
            case 1:
                message.cardDeck = 1;
                break;
            }
            if (object.gameTopicLink != null)
                message.gameTopicLink = String(object.gameTopicLink);
            return message;
        };

        /**
         * Creates a plain object from a GameStatusUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.GameStatusUpdate
         * @static
         * @param {messaging.GameStatusUpdate} message GameStatusUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameStatusUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.highlightedPlayers = [];
                object.majorityJoinedPlayers = [];
                object.hosts = [];
            }
            if (options.objects || options.defaults)
                object.playerStatus = {};
            if (options.defaults) {
                object.majorityVotedCard = options.enums === String ? "SPECIAL_NOTCHOSEN" : 0;
                object.unanimousVote = false;
                object.gameStatus = options.enums === String ? "NEW" : 0;
                object.gameTitle = "";
                object.gameDeck = "";
                object.gameTopic = "";
                object.gameRound = 0;
                object.cardDeck = options.enums === String ? "DEFAULT" : 0;
                object.gameTopicLink = "";
            }
            let keys2;
            if (message.playerStatus && (keys2 = Object.keys(message.playerStatus)).length) {
                object.playerStatus = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.playerStatus[keys2[j]] = options.enums === String ? $root.messaging.CardDeckCards[message.playerStatus[keys2[j]]] : message.playerStatus[keys2[j]];
            }
            if (message.highlightedPlayers && message.highlightedPlayers.length) {
                object.highlightedPlayers = [];
                for (let j = 0; j < message.highlightedPlayers.length; ++j)
                    object.highlightedPlayers[j] = message.highlightedPlayers[j];
            }
            if (message.majorityJoinedPlayers && message.majorityJoinedPlayers.length) {
                object.majorityJoinedPlayers = [];
                for (let j = 0; j < message.majorityJoinedPlayers.length; ++j)
                    object.majorityJoinedPlayers[j] = message.majorityJoinedPlayers[j];
            }
            if (message.majorityVotedCard != null && message.hasOwnProperty("majorityVotedCard"))
                object.majorityVotedCard = options.enums === String ? $root.messaging.CardDeckCards[message.majorityVotedCard] : message.majorityVotedCard;
            if (message.unanimousVote != null && message.hasOwnProperty("unanimousVote"))
                object.unanimousVote = message.unanimousVote;
            if (message.hosts && message.hosts.length) {
                object.hosts = [];
                for (let j = 0; j < message.hosts.length; ++j)
                    object.hosts[j] = message.hosts[j];
            }
            if (message.gameStatus != null && message.hasOwnProperty("gameStatus"))
                object.gameStatus = options.enums === String ? $root.messaging.GameStatus[message.gameStatus] : message.gameStatus;
            if (message.gameTitle != null && message.hasOwnProperty("gameTitle"))
                object.gameTitle = message.gameTitle;
            if (message.gameDeck != null && message.hasOwnProperty("gameDeck"))
                object.gameDeck = message.gameDeck;
            if (message.gameTopic != null && message.hasOwnProperty("gameTopic"))
                object.gameTopic = message.gameTopic;
            if (message.gameRound != null && message.hasOwnProperty("gameRound"))
                object.gameRound = message.gameRound;
            if (message.cardDeck != null && message.hasOwnProperty("cardDeck"))
                object.cardDeck = options.enums === String ? $root.messaging.CardDeckType[message.cardDeck] : message.cardDeck;
            if (message.gameTopicLink != null && message.hasOwnProperty("gameTopicLink"))
                object.gameTopicLink = message.gameTopicLink;
            return object;
        };

        /**
         * Converts this GameStatusUpdate to JSON.
         * @function toJSON
         * @memberof messaging.GameStatusUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameStatusUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameStatusUpdate;
    })();

    messaging.PlayerUpdate = (function() {

        /**
         * Properties of a PlayerUpdate.
         * @memberof messaging
         * @interface IPlayerUpdate
         * @property {messaging.CardDeckCards|null} [card] PlayerUpdate card
         */

        /**
         * Constructs a new PlayerUpdate.
         * @memberof messaging
         * @classdesc Represents a PlayerUpdate.
         * @implements IPlayerUpdate
         * @constructor
         * @param {messaging.IPlayerUpdate=} [properties] Properties to set
         */
        function PlayerUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerUpdate card.
         * @member {messaging.CardDeckCards} card
         * @memberof messaging.PlayerUpdate
         * @instance
         */
        PlayerUpdate.prototype.card = 0;

        /**
         * Creates a new PlayerUpdate instance using the specified properties.
         * @function create
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {messaging.IPlayerUpdate=} [properties] Properties to set
         * @returns {messaging.PlayerUpdate} PlayerUpdate instance
         */
        PlayerUpdate.create = function create(properties) {
            return new PlayerUpdate(properties);
        };

        /**
         * Encodes the specified PlayerUpdate message. Does not implicitly {@link messaging.PlayerUpdate.verify|verify} messages.
         * @function encode
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {messaging.IPlayerUpdate} message PlayerUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.card);
            return writer;
        };

        /**
         * Encodes the specified PlayerUpdate message, length delimited. Does not implicitly {@link messaging.PlayerUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {messaging.IPlayerUpdate} message PlayerUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.PlayerUpdate} PlayerUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.PlayerUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.card = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.PlayerUpdate} PlayerUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerUpdate message.
         * @function verify
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.card != null && message.hasOwnProperty("card"))
                switch (message.card) {
                default:
                    return "card: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    break;
                }
            return null;
        };

        /**
         * Creates a PlayerUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.PlayerUpdate} PlayerUpdate
         */
        PlayerUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.PlayerUpdate)
                return object;
            let message = new $root.messaging.PlayerUpdate();
            switch (object.card) {
            case "SPECIAL_NOTCHOSEN":
            case 0:
                message.card = 0;
                break;
            case "SPECIAL_CHOSEN":
            case 1:
                message.card = 1;
                break;
            case "SPECIAL_JOINMAJORITY":
            case 2:
                message.card = 2;
                break;
            case "SPECIAL_NOMAJORITY":
            case 3:
                message.card = 3;
                break;
            case "DEFAULT_ZERO":
            case 4:
                message.card = 4;
                break;
            case "DEFAULT_PFIVE":
            case 5:
                message.card = 5;
                break;
            case "DEFAULT_ONE":
            case 6:
                message.card = 6;
                break;
            case "DEFAULT_TWO":
            case 7:
                message.card = 7;
                break;
            case "DEFAULT_THREE":
            case 8:
                message.card = 8;
                break;
            case "DEFAULT_FIVE":
            case 9:
                message.card = 9;
                break;
            case "DEFAULT_EIGHT":
            case 10:
                message.card = 10;
                break;
            case "DEFAULT_THIRTEEN":
            case 11:
                message.card = 11;
                break;
            case "DEFAULT_TWENTY":
            case 12:
                message.card = 12;
                break;
            case "DEFAULT_FOURTY":
            case 13:
                message.card = 13;
                break;
            case "DEFAULT_HUNDRED":
            case 14:
                message.card = 14;
                break;
            case "TSHIRT_S":
            case 15:
                message.card = 15;
                break;
            case "TSHIRT_M":
            case 16:
                message.card = 16;
                break;
            case "TSHIRT_L":
            case 17:
                message.card = 17;
                break;
            case "TSHIRT_XL":
            case 18:
                message.card = 18;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.PlayerUpdate
         * @static
         * @param {messaging.PlayerUpdate} message PlayerUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.card = options.enums === String ? "SPECIAL_NOTCHOSEN" : 0;
            if (message.card != null && message.hasOwnProperty("card"))
                object.card = options.enums === String ? $root.messaging.CardDeckCards[message.card] : message.card;
            return object;
        };

        /**
         * Converts this PlayerUpdate to JSON.
         * @function toJSON
         * @memberof messaging.PlayerUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerUpdate;
    })();

    messaging.HostUpdate = (function() {

        /**
         * Properties of a HostUpdate.
         * @memberof messaging
         * @interface IHostUpdate
         * @property {messaging.GameStatus|null} [gameStatus] HostUpdate gameStatus
         * @property {string|null} [gameTitle] HostUpdate gameTitle
         * @property {string|null} [gameTopic] HostUpdate gameTopic
         * @property {string|null} [gameTopicLink] HostUpdate gameTopicLink
         */

        /**
         * Constructs a new HostUpdate.
         * @memberof messaging
         * @classdesc Represents a HostUpdate.
         * @implements IHostUpdate
         * @constructor
         * @param {messaging.IHostUpdate=} [properties] Properties to set
         */
        function HostUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HostUpdate gameStatus.
         * @member {messaging.GameStatus} gameStatus
         * @memberof messaging.HostUpdate
         * @instance
         */
        HostUpdate.prototype.gameStatus = 0;

        /**
         * HostUpdate gameTitle.
         * @member {string} gameTitle
         * @memberof messaging.HostUpdate
         * @instance
         */
        HostUpdate.prototype.gameTitle = "";

        /**
         * HostUpdate gameTopic.
         * @member {string} gameTopic
         * @memberof messaging.HostUpdate
         * @instance
         */
        HostUpdate.prototype.gameTopic = "";

        /**
         * HostUpdate gameTopicLink.
         * @member {string} gameTopicLink
         * @memberof messaging.HostUpdate
         * @instance
         */
        HostUpdate.prototype.gameTopicLink = "";

        /**
         * Creates a new HostUpdate instance using the specified properties.
         * @function create
         * @memberof messaging.HostUpdate
         * @static
         * @param {messaging.IHostUpdate=} [properties] Properties to set
         * @returns {messaging.HostUpdate} HostUpdate instance
         */
        HostUpdate.create = function create(properties) {
            return new HostUpdate(properties);
        };

        /**
         * Encodes the specified HostUpdate message. Does not implicitly {@link messaging.HostUpdate.verify|verify} messages.
         * @function encode
         * @memberof messaging.HostUpdate
         * @static
         * @param {messaging.IHostUpdate} message HostUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HostUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameStatus != null && Object.hasOwnProperty.call(message, "gameStatus"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameStatus);
            if (message.gameTitle != null && Object.hasOwnProperty.call(message, "gameTitle"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gameTitle);
            if (message.gameTopic != null && Object.hasOwnProperty.call(message, "gameTopic"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.gameTopic);
            if (message.gameTopicLink != null && Object.hasOwnProperty.call(message, "gameTopicLink"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.gameTopicLink);
            return writer;
        };

        /**
         * Encodes the specified HostUpdate message, length delimited. Does not implicitly {@link messaging.HostUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.HostUpdate
         * @static
         * @param {messaging.IHostUpdate} message HostUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HostUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HostUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.HostUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.HostUpdate} HostUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HostUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.HostUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameStatus = reader.int32();
                    break;
                case 2:
                    message.gameTitle = reader.string();
                    break;
                case 3:
                    message.gameTopic = reader.string();
                    break;
                case 4:
                    message.gameTopicLink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HostUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.HostUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.HostUpdate} HostUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HostUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HostUpdate message.
         * @function verify
         * @memberof messaging.HostUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HostUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameStatus != null && message.hasOwnProperty("gameStatus"))
                switch (message.gameStatus) {
                default:
                    return "gameStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.gameTitle != null && message.hasOwnProperty("gameTitle"))
                if (!$util.isString(message.gameTitle))
                    return "gameTitle: string expected";
            if (message.gameTopic != null && message.hasOwnProperty("gameTopic"))
                if (!$util.isString(message.gameTopic))
                    return "gameTopic: string expected";
            if (message.gameTopicLink != null && message.hasOwnProperty("gameTopicLink"))
                if (!$util.isString(message.gameTopicLink))
                    return "gameTopicLink: string expected";
            return null;
        };

        /**
         * Creates a HostUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.HostUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.HostUpdate} HostUpdate
         */
        HostUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.HostUpdate)
                return object;
            let message = new $root.messaging.HostUpdate();
            switch (object.gameStatus) {
            case "NEW":
            case 0:
                message.gameStatus = 0;
                break;
            case "RUNNING":
            case 1:
                message.gameStatus = 1;
                break;
            case "FINISHED":
            case 2:
                message.gameStatus = 2;
                break;
            }
            if (object.gameTitle != null)
                message.gameTitle = String(object.gameTitle);
            if (object.gameTopic != null)
                message.gameTopic = String(object.gameTopic);
            if (object.gameTopicLink != null)
                message.gameTopicLink = String(object.gameTopicLink);
            return message;
        };

        /**
         * Creates a plain object from a HostUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.HostUpdate
         * @static
         * @param {messaging.HostUpdate} message HostUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HostUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.gameStatus = options.enums === String ? "NEW" : 0;
                object.gameTitle = "";
                object.gameTopic = "";
                object.gameTopicLink = "";
            }
            if (message.gameStatus != null && message.hasOwnProperty("gameStatus"))
                object.gameStatus = options.enums === String ? $root.messaging.GameStatus[message.gameStatus] : message.gameStatus;
            if (message.gameTitle != null && message.hasOwnProperty("gameTitle"))
                object.gameTitle = message.gameTitle;
            if (message.gameTopic != null && message.hasOwnProperty("gameTopic"))
                object.gameTopic = message.gameTopic;
            if (message.gameTopicLink != null && message.hasOwnProperty("gameTopicLink"))
                object.gameTopicLink = message.gameTopicLink;
            return object;
        };

        /**
         * Converts this HostUpdate to JSON.
         * @function toJSON
         * @memberof messaging.HostUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HostUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HostUpdate;
    })();

    messaging.KickUser = (function() {

        /**
         * Properties of a KickUser.
         * @memberof messaging
         * @interface IKickUser
         * @property {string|null} [UserID] KickUser UserID
         */

        /**
         * Constructs a new KickUser.
         * @memberof messaging
         * @classdesc Represents a KickUser.
         * @implements IKickUser
         * @constructor
         * @param {messaging.IKickUser=} [properties] Properties to set
         */
        function KickUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KickUser UserID.
         * @member {string} UserID
         * @memberof messaging.KickUser
         * @instance
         */
        KickUser.prototype.UserID = "";

        /**
         * Creates a new KickUser instance using the specified properties.
         * @function create
         * @memberof messaging.KickUser
         * @static
         * @param {messaging.IKickUser=} [properties] Properties to set
         * @returns {messaging.KickUser} KickUser instance
         */
        KickUser.create = function create(properties) {
            return new KickUser(properties);
        };

        /**
         * Encodes the specified KickUser message. Does not implicitly {@link messaging.KickUser.verify|verify} messages.
         * @function encode
         * @memberof messaging.KickUser
         * @static
         * @param {messaging.IKickUser} message KickUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KickUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.UserID != null && Object.hasOwnProperty.call(message, "UserID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.UserID);
            return writer;
        };

        /**
         * Encodes the specified KickUser message, length delimited. Does not implicitly {@link messaging.KickUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.KickUser
         * @static
         * @param {messaging.IKickUser} message KickUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KickUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KickUser message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.KickUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.KickUser} KickUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KickUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.KickUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.UserID = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KickUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.KickUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.KickUser} KickUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KickUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KickUser message.
         * @function verify
         * @memberof messaging.KickUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KickUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.UserID != null && message.hasOwnProperty("UserID"))
                if (!$util.isString(message.UserID))
                    return "UserID: string expected";
            return null;
        };

        /**
         * Creates a KickUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.KickUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.KickUser} KickUser
         */
        KickUser.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.KickUser)
                return object;
            let message = new $root.messaging.KickUser();
            if (object.UserID != null)
                message.UserID = String(object.UserID);
            return message;
        };

        /**
         * Creates a plain object from a KickUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.KickUser
         * @static
         * @param {messaging.KickUser} message KickUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KickUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.UserID = "";
            if (message.UserID != null && message.hasOwnProperty("UserID"))
                object.UserID = message.UserID;
            return object;
        };

        /**
         * Converts this KickUser to JSON.
         * @function toJSON
         * @memberof messaging.KickUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KickUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KickUser;
    })();

    messaging.OpenGame = (function() {

        /**
         * Properties of an OpenGame.
         * @memberof messaging
         * @interface IOpenGame
         * @property {string|null} [gameTitle] OpenGame gameTitle
         * @property {messaging.CardDeckType|null} [cardDeck] OpenGame cardDeck
         */

        /**
         * Constructs a new OpenGame.
         * @memberof messaging
         * @classdesc Represents an OpenGame.
         * @implements IOpenGame
         * @constructor
         * @param {messaging.IOpenGame=} [properties] Properties to set
         */
        function OpenGame(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OpenGame gameTitle.
         * @member {string} gameTitle
         * @memberof messaging.OpenGame
         * @instance
         */
        OpenGame.prototype.gameTitle = "";

        /**
         * OpenGame cardDeck.
         * @member {messaging.CardDeckType} cardDeck
         * @memberof messaging.OpenGame
         * @instance
         */
        OpenGame.prototype.cardDeck = 0;

        /**
         * Creates a new OpenGame instance using the specified properties.
         * @function create
         * @memberof messaging.OpenGame
         * @static
         * @param {messaging.IOpenGame=} [properties] Properties to set
         * @returns {messaging.OpenGame} OpenGame instance
         */
        OpenGame.create = function create(properties) {
            return new OpenGame(properties);
        };

        /**
         * Encodes the specified OpenGame message. Does not implicitly {@link messaging.OpenGame.verify|verify} messages.
         * @function encode
         * @memberof messaging.OpenGame
         * @static
         * @param {messaging.IOpenGame} message OpenGame message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenGame.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameTitle != null && Object.hasOwnProperty.call(message, "gameTitle"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameTitle);
            if (message.cardDeck != null && Object.hasOwnProperty.call(message, "cardDeck"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.cardDeck);
            return writer;
        };

        /**
         * Encodes the specified OpenGame message, length delimited. Does not implicitly {@link messaging.OpenGame.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.OpenGame
         * @static
         * @param {messaging.IOpenGame} message OpenGame message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenGame.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OpenGame message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.OpenGame
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.OpenGame} OpenGame
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenGame.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.OpenGame();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameTitle = reader.string();
                    break;
                case 2:
                    message.cardDeck = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OpenGame message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.OpenGame
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.OpenGame} OpenGame
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenGame.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OpenGame message.
         * @function verify
         * @memberof messaging.OpenGame
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OpenGame.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameTitle != null && message.hasOwnProperty("gameTitle"))
                if (!$util.isString(message.gameTitle))
                    return "gameTitle: string expected";
            if (message.cardDeck != null && message.hasOwnProperty("cardDeck"))
                switch (message.cardDeck) {
                default:
                    return "cardDeck: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates an OpenGame message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.OpenGame
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.OpenGame} OpenGame
         */
        OpenGame.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.OpenGame)
                return object;
            let message = new $root.messaging.OpenGame();
            if (object.gameTitle != null)
                message.gameTitle = String(object.gameTitle);
            switch (object.cardDeck) {
            case "DEFAULT":
            case 0:
                message.cardDeck = 0;
                break;
            case "TSHIRT":
            case 1:
                message.cardDeck = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an OpenGame message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.OpenGame
         * @static
         * @param {messaging.OpenGame} message OpenGame
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OpenGame.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.gameTitle = "";
                object.cardDeck = options.enums === String ? "DEFAULT" : 0;
            }
            if (message.gameTitle != null && message.hasOwnProperty("gameTitle"))
                object.gameTitle = message.gameTitle;
            if (message.cardDeck != null && message.hasOwnProperty("cardDeck"))
                object.cardDeck = options.enums === String ? $root.messaging.CardDeckType[message.cardDeck] : message.cardDeck;
            return object;
        };

        /**
         * Converts this OpenGame to JSON.
         * @function toJSON
         * @memberof messaging.OpenGame
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OpenGame.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OpenGame;
    })();

    messaging.UserToken = (function() {

        /**
         * Properties of a UserToken.
         * @memberof messaging
         * @interface IUserToken
         * @property {string|null} [userID] UserToken userID
         * @property {string|null} [token] UserToken token
         */

        /**
         * Constructs a new UserToken.
         * @memberof messaging
         * @classdesc Represents a UserToken.
         * @implements IUserToken
         * @constructor
         * @param {messaging.IUserToken=} [properties] Properties to set
         */
        function UserToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserToken userID.
         * @member {string} userID
         * @memberof messaging.UserToken
         * @instance
         */
        UserToken.prototype.userID = "";

        /**
         * UserToken token.
         * @member {string} token
         * @memberof messaging.UserToken
         * @instance
         */
        UserToken.prototype.token = "";

        /**
         * Creates a new UserToken instance using the specified properties.
         * @function create
         * @memberof messaging.UserToken
         * @static
         * @param {messaging.IUserToken=} [properties] Properties to set
         * @returns {messaging.UserToken} UserToken instance
         */
        UserToken.create = function create(properties) {
            return new UserToken(properties);
        };

        /**
         * Encodes the specified UserToken message. Does not implicitly {@link messaging.UserToken.verify|verify} messages.
         * @function encode
         * @memberof messaging.UserToken
         * @static
         * @param {messaging.IUserToken} message UserToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userID != null && Object.hasOwnProperty.call(message, "userID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userID);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified UserToken message, length delimited. Does not implicitly {@link messaging.UserToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.UserToken
         * @static
         * @param {messaging.IUserToken} message UserToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserToken message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.UserToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.UserToken} UserToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.UserToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userID = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.UserToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.UserToken} UserToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserToken message.
         * @function verify
         * @memberof messaging.UserToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userID != null && message.hasOwnProperty("userID"))
                if (!$util.isString(message.userID))
                    return "userID: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a UserToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.UserToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.UserToken} UserToken
         */
        UserToken.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.UserToken)
                return object;
            let message = new $root.messaging.UserToken();
            if (object.userID != null)
                message.userID = String(object.userID);
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a UserToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.UserToken
         * @static
         * @param {messaging.UserToken} message UserToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userID = "";
                object.token = "";
            }
            if (message.userID != null && message.hasOwnProperty("userID"))
                object.userID = message.userID;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this UserToken to JSON.
         * @function toJSON
         * @memberof messaging.UserToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserToken;
    })();

    messaging.Container = (function() {

        /**
         * Properties of a Container.
         * @memberof messaging
         * @interface IContainer
         * @property {string|null} [userID] Container userID
         * @property {string|null} [gameID] Container gameID
         * @property {string|null} [gatewayID] Container gatewayID
         * @property {messaging.IConnection|null} [connection] Container connection
         * @property {messaging.IGameStatusUpdate|null} [gameStatusUpdate] Container gameStatusUpdate
         * @property {messaging.IPlayerUpdate|null} [playerUpdate] Container playerUpdate
         * @property {messaging.IHostUpdate|null} [hostUpdate] Container hostUpdate
         * @property {messaging.IUserToken|null} [userToken] Container userToken
         * @property {messaging.IOpenGame|null} [openGame] Container openGame
         * @property {messaging.IKickUser|null} [kickUser] Container kickUser
         */

        /**
         * Constructs a new Container.
         * @memberof messaging
         * @classdesc Represents a Container.
         * @implements IContainer
         * @constructor
         * @param {messaging.IContainer=} [properties] Properties to set
         */
        function Container(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Container userID.
         * @member {string} userID
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.userID = "";

        /**
         * Container gameID.
         * @member {string} gameID
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.gameID = "";

        /**
         * Container gatewayID.
         * @member {string} gatewayID
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.gatewayID = "";

        /**
         * Container connection.
         * @member {messaging.IConnection|null|undefined} connection
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.connection = null;

        /**
         * Container gameStatusUpdate.
         * @member {messaging.IGameStatusUpdate|null|undefined} gameStatusUpdate
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.gameStatusUpdate = null;

        /**
         * Container playerUpdate.
         * @member {messaging.IPlayerUpdate|null|undefined} playerUpdate
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.playerUpdate = null;

        /**
         * Container hostUpdate.
         * @member {messaging.IHostUpdate|null|undefined} hostUpdate
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.hostUpdate = null;

        /**
         * Container userToken.
         * @member {messaging.IUserToken|null|undefined} userToken
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.userToken = null;

        /**
         * Container openGame.
         * @member {messaging.IOpenGame|null|undefined} openGame
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.openGame = null;

        /**
         * Container kickUser.
         * @member {messaging.IKickUser|null|undefined} kickUser
         * @memberof messaging.Container
         * @instance
         */
        Container.prototype.kickUser = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Container msg.
         * @member {"connection"|"gameStatusUpdate"|"playerUpdate"|"hostUpdate"|"userToken"|"openGame"|"kickUser"|undefined} msg
         * @memberof messaging.Container
         * @instance
         */
        Object.defineProperty(Container.prototype, "msg", {
            get: $util.oneOfGetter($oneOfFields = ["connection", "gameStatusUpdate", "playerUpdate", "hostUpdate", "userToken", "openGame", "kickUser"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Container instance using the specified properties.
         * @function create
         * @memberof messaging.Container
         * @static
         * @param {messaging.IContainer=} [properties] Properties to set
         * @returns {messaging.Container} Container instance
         */
        Container.create = function create(properties) {
            return new Container(properties);
        };

        /**
         * Encodes the specified Container message. Does not implicitly {@link messaging.Container.verify|verify} messages.
         * @function encode
         * @memberof messaging.Container
         * @static
         * @param {messaging.IContainer} message Container message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Container.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userID != null && Object.hasOwnProperty.call(message, "userID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userID);
            if (message.gameID != null && Object.hasOwnProperty.call(message, "gameID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gameID);
            if (message.gatewayID != null && Object.hasOwnProperty.call(message, "gatewayID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.gatewayID);
            if (message.connection != null && Object.hasOwnProperty.call(message, "connection"))
                $root.messaging.Connection.encode(message.connection, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.gameStatusUpdate != null && Object.hasOwnProperty.call(message, "gameStatusUpdate"))
                $root.messaging.GameStatusUpdate.encode(message.gameStatusUpdate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.playerUpdate != null && Object.hasOwnProperty.call(message, "playerUpdate"))
                $root.messaging.PlayerUpdate.encode(message.playerUpdate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.hostUpdate != null && Object.hasOwnProperty.call(message, "hostUpdate"))
                $root.messaging.HostUpdate.encode(message.hostUpdate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.userToken != null && Object.hasOwnProperty.call(message, "userToken"))
                $root.messaging.UserToken.encode(message.userToken, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.openGame != null && Object.hasOwnProperty.call(message, "openGame"))
                $root.messaging.OpenGame.encode(message.openGame, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.kickUser != null && Object.hasOwnProperty.call(message, "kickUser"))
                $root.messaging.KickUser.encode(message.kickUser, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Container message, length delimited. Does not implicitly {@link messaging.Container.verify|verify} messages.
         * @function encodeDelimited
         * @memberof messaging.Container
         * @static
         * @param {messaging.IContainer} message Container message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Container.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Container message from the specified reader or buffer.
         * @function decode
         * @memberof messaging.Container
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {messaging.Container} Container
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Container.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.messaging.Container();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userID = reader.string();
                    break;
                case 2:
                    message.gameID = reader.string();
                    break;
                case 3:
                    message.gatewayID = reader.string();
                    break;
                case 4:
                    message.connection = $root.messaging.Connection.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.gameStatusUpdate = $root.messaging.GameStatusUpdate.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.playerUpdate = $root.messaging.PlayerUpdate.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.hostUpdate = $root.messaging.HostUpdate.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.userToken = $root.messaging.UserToken.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.openGame = $root.messaging.OpenGame.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.kickUser = $root.messaging.KickUser.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Container message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof messaging.Container
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {messaging.Container} Container
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Container.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Container message.
         * @function verify
         * @memberof messaging.Container
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Container.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userID != null && message.hasOwnProperty("userID"))
                if (!$util.isString(message.userID))
                    return "userID: string expected";
            if (message.gameID != null && message.hasOwnProperty("gameID"))
                if (!$util.isString(message.gameID))
                    return "gameID: string expected";
            if (message.gatewayID != null && message.hasOwnProperty("gatewayID"))
                if (!$util.isString(message.gatewayID))
                    return "gatewayID: string expected";
            if (message.connection != null && message.hasOwnProperty("connection")) {
                properties.msg = 1;
                {
                    let error = $root.messaging.Connection.verify(message.connection);
                    if (error)
                        return "connection." + error;
                }
            }
            if (message.gameStatusUpdate != null && message.hasOwnProperty("gameStatusUpdate")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.messaging.GameStatusUpdate.verify(message.gameStatusUpdate);
                    if (error)
                        return "gameStatusUpdate." + error;
                }
            }
            if (message.playerUpdate != null && message.hasOwnProperty("playerUpdate")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.messaging.PlayerUpdate.verify(message.playerUpdate);
                    if (error)
                        return "playerUpdate." + error;
                }
            }
            if (message.hostUpdate != null && message.hasOwnProperty("hostUpdate")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.messaging.HostUpdate.verify(message.hostUpdate);
                    if (error)
                        return "hostUpdate." + error;
                }
            }
            if (message.userToken != null && message.hasOwnProperty("userToken")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.messaging.UserToken.verify(message.userToken);
                    if (error)
                        return "userToken." + error;
                }
            }
            if (message.openGame != null && message.hasOwnProperty("openGame")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.messaging.OpenGame.verify(message.openGame);
                    if (error)
                        return "openGame." + error;
                }
            }
            if (message.kickUser != null && message.hasOwnProperty("kickUser")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.messaging.KickUser.verify(message.kickUser);
                    if (error)
                        return "kickUser." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Container message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof messaging.Container
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {messaging.Container} Container
         */
        Container.fromObject = function fromObject(object) {
            if (object instanceof $root.messaging.Container)
                return object;
            let message = new $root.messaging.Container();
            if (object.userID != null)
                message.userID = String(object.userID);
            if (object.gameID != null)
                message.gameID = String(object.gameID);
            if (object.gatewayID != null)
                message.gatewayID = String(object.gatewayID);
            if (object.connection != null) {
                if (typeof object.connection !== "object")
                    throw TypeError(".messaging.Container.connection: object expected");
                message.connection = $root.messaging.Connection.fromObject(object.connection);
            }
            if (object.gameStatusUpdate != null) {
                if (typeof object.gameStatusUpdate !== "object")
                    throw TypeError(".messaging.Container.gameStatusUpdate: object expected");
                message.gameStatusUpdate = $root.messaging.GameStatusUpdate.fromObject(object.gameStatusUpdate);
            }
            if (object.playerUpdate != null) {
                if (typeof object.playerUpdate !== "object")
                    throw TypeError(".messaging.Container.playerUpdate: object expected");
                message.playerUpdate = $root.messaging.PlayerUpdate.fromObject(object.playerUpdate);
            }
            if (object.hostUpdate != null) {
                if (typeof object.hostUpdate !== "object")
                    throw TypeError(".messaging.Container.hostUpdate: object expected");
                message.hostUpdate = $root.messaging.HostUpdate.fromObject(object.hostUpdate);
            }
            if (object.userToken != null) {
                if (typeof object.userToken !== "object")
                    throw TypeError(".messaging.Container.userToken: object expected");
                message.userToken = $root.messaging.UserToken.fromObject(object.userToken);
            }
            if (object.openGame != null) {
                if (typeof object.openGame !== "object")
                    throw TypeError(".messaging.Container.openGame: object expected");
                message.openGame = $root.messaging.OpenGame.fromObject(object.openGame);
            }
            if (object.kickUser != null) {
                if (typeof object.kickUser !== "object")
                    throw TypeError(".messaging.Container.kickUser: object expected");
                message.kickUser = $root.messaging.KickUser.fromObject(object.kickUser);
            }
            return message;
        };

        /**
         * Creates a plain object from a Container message. Also converts values to other types if specified.
         * @function toObject
         * @memberof messaging.Container
         * @static
         * @param {messaging.Container} message Container
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Container.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userID = "";
                object.gameID = "";
                object.gatewayID = "";
            }
            if (message.userID != null && message.hasOwnProperty("userID"))
                object.userID = message.userID;
            if (message.gameID != null && message.hasOwnProperty("gameID"))
                object.gameID = message.gameID;
            if (message.gatewayID != null && message.hasOwnProperty("gatewayID"))
                object.gatewayID = message.gatewayID;
            if (message.connection != null && message.hasOwnProperty("connection")) {
                object.connection = $root.messaging.Connection.toObject(message.connection, options);
                if (options.oneofs)
                    object.msg = "connection";
            }
            if (message.gameStatusUpdate != null && message.hasOwnProperty("gameStatusUpdate")) {
                object.gameStatusUpdate = $root.messaging.GameStatusUpdate.toObject(message.gameStatusUpdate, options);
                if (options.oneofs)
                    object.msg = "gameStatusUpdate";
            }
            if (message.playerUpdate != null && message.hasOwnProperty("playerUpdate")) {
                object.playerUpdate = $root.messaging.PlayerUpdate.toObject(message.playerUpdate, options);
                if (options.oneofs)
                    object.msg = "playerUpdate";
            }
            if (message.hostUpdate != null && message.hasOwnProperty("hostUpdate")) {
                object.hostUpdate = $root.messaging.HostUpdate.toObject(message.hostUpdate, options);
                if (options.oneofs)
                    object.msg = "hostUpdate";
            }
            if (message.userToken != null && message.hasOwnProperty("userToken")) {
                object.userToken = $root.messaging.UserToken.toObject(message.userToken, options);
                if (options.oneofs)
                    object.msg = "userToken";
            }
            if (message.openGame != null && message.hasOwnProperty("openGame")) {
                object.openGame = $root.messaging.OpenGame.toObject(message.openGame, options);
                if (options.oneofs)
                    object.msg = "openGame";
            }
            if (message.kickUser != null && message.hasOwnProperty("kickUser")) {
                object.kickUser = $root.messaging.KickUser.toObject(message.kickUser, options);
                if (options.oneofs)
                    object.msg = "kickUser";
            }
            return object;
        };

        /**
         * Converts this Container to JSON.
         * @function toJSON
         * @memberof messaging.Container
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Container.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Container;
    })();

    /**
     * CardDeckType enum.
     * @name messaging.CardDeckType
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} TSHIRT=1 TSHIRT value
     */
    messaging.CardDeckType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "TSHIRT"] = 1;
        return values;
    })();

    /**
     * CardDeckCards enum.
     * @name messaging.CardDeckCards
     * @enum {number}
     * @property {number} SPECIAL_NOTCHOSEN=0 SPECIAL_NOTCHOSEN value
     * @property {number} SPECIAL_CHOSEN=1 SPECIAL_CHOSEN value
     * @property {number} SPECIAL_JOINMAJORITY=2 SPECIAL_JOINMAJORITY value
     * @property {number} SPECIAL_NOMAJORITY=3 SPECIAL_NOMAJORITY value
     * @property {number} DEFAULT_ZERO=4 DEFAULT_ZERO value
     * @property {number} DEFAULT_PFIVE=5 DEFAULT_PFIVE value
     * @property {number} DEFAULT_ONE=6 DEFAULT_ONE value
     * @property {number} DEFAULT_TWO=7 DEFAULT_TWO value
     * @property {number} DEFAULT_THREE=8 DEFAULT_THREE value
     * @property {number} DEFAULT_FIVE=9 DEFAULT_FIVE value
     * @property {number} DEFAULT_EIGHT=10 DEFAULT_EIGHT value
     * @property {number} DEFAULT_THIRTEEN=11 DEFAULT_THIRTEEN value
     * @property {number} DEFAULT_TWENTY=12 DEFAULT_TWENTY value
     * @property {number} DEFAULT_FOURTY=13 DEFAULT_FOURTY value
     * @property {number} DEFAULT_HUNDRED=14 DEFAULT_HUNDRED value
     * @property {number} TSHIRT_S=15 TSHIRT_S value
     * @property {number} TSHIRT_M=16 TSHIRT_M value
     * @property {number} TSHIRT_L=17 TSHIRT_L value
     * @property {number} TSHIRT_XL=18 TSHIRT_XL value
     */
    messaging.CardDeckCards = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SPECIAL_NOTCHOSEN"] = 0;
        values[valuesById[1] = "SPECIAL_CHOSEN"] = 1;
        values[valuesById[2] = "SPECIAL_JOINMAJORITY"] = 2;
        values[valuesById[3] = "SPECIAL_NOMAJORITY"] = 3;
        values[valuesById[4] = "DEFAULT_ZERO"] = 4;
        values[valuesById[5] = "DEFAULT_PFIVE"] = 5;
        values[valuesById[6] = "DEFAULT_ONE"] = 6;
        values[valuesById[7] = "DEFAULT_TWO"] = 7;
        values[valuesById[8] = "DEFAULT_THREE"] = 8;
        values[valuesById[9] = "DEFAULT_FIVE"] = 9;
        values[valuesById[10] = "DEFAULT_EIGHT"] = 10;
        values[valuesById[11] = "DEFAULT_THIRTEEN"] = 11;
        values[valuesById[12] = "DEFAULT_TWENTY"] = 12;
        values[valuesById[13] = "DEFAULT_FOURTY"] = 13;
        values[valuesById[14] = "DEFAULT_HUNDRED"] = 14;
        values[valuesById[15] = "TSHIRT_S"] = 15;
        values[valuesById[16] = "TSHIRT_M"] = 16;
        values[valuesById[17] = "TSHIRT_L"] = 17;
        values[valuesById[18] = "TSHIRT_XL"] = 18;
        return values;
    })();

    return messaging;
})();

export { $root as default };
