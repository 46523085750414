import RobustWebSocket from "robust-websocket";
import _Vue from "vue";
import {GameStateModel} from "@/store/models/GameStateModel";
import {ConnectionErrorNoSessionCookie} from "@/store/models/GameStateModel";
import {messaging} from "./../rio-proto.js";
import protobuf from 'protobufjs'

let ws: any;

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function WebsocketPlugin(Vue: typeof _Vue, options?: any): void {
	Vue.prototype.$websocketConnect = (
		gameID: string,
		hosting: boolean,
		playerName?: string,
	) => {

		RobustWebSocket.prototype.binaryType = "arraybuffer";

		// use empty playername to connect as host
		ws = new RobustWebSocket(
			process!.env!.VUE_APP_API_WS_PROTO! +
			process!.env!.VUE_APP_GATEWAY_URL! +
			"/connect/" +
			gameID +
			"?playerName=" +
			playerName,
			null,
			{
				shouldReconnect: function (event: any, ws: any) {
					// do not reconnect on those error codes
					if (event.code === 1008 || event.code === 1011) return;

					// do not reconnect on our custom error code 4000 / Access Forbidden
					if (event.code === 4000) {
						options.store.dispatch(
							"gameStateModule/set",
							new GameStateModel(new messaging.Container, event.reason)
						)
						ws.close();
						return;
					}

					// throw an error when a user tries to join without cookie
					if (event.code === 4001) {
						options.store.dispatch(
							"gameStateModule/set",
							new GameStateModel(new messaging.Container, ConnectionErrorNoSessionCookie)
						)
						ws.close();
						return;
					}

					// use the following timeouts based on number of reconnect attempts
					if (ws.attempts < 6) {
						return [0, 3000, 3000, 3000, 3000, 10000][ws.attempts];
					}

					// after 6 timeouts, start to backoff exponentially
					return Math.pow(1.5, ws.attempts) * 500;
				}
			}
		);

		ws.addEventListener("open", function () {
			sendMessage(messaging.Container.create(
				{
					connection: {
						state: messaging.Connection.State.INIT,
						hosting: hosting,
					}
				}
			)
			);
		});

		ws.addEventListener("message", function (event: any) {
			handleMessage(event);
		});

	}

	Vue.prototype.$disconnect = () => {
		ws.close();
	};


	Vue.prototype.$websocketSend = (c: messaging.Container) => {
		sendMessage(c);
	}

	function sendMessage(c: messaging.Container) {
		ws.send(messaging.Container.encode(c).finish());
	}

	function handleMessage(event: any) {
		const buf = protobuf.util.newBuffer(event.data);
		const decoded = messaging.Container.decode(buf)
		console.log("received new status update: " + JSON.stringify(decoded.toJSON()));
		options.store.dispatch("gameStateModule/set", new GameStateModel(decoded, ""));
	}

}

