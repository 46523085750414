import axios from "axios";
import protobuf from 'protobufjs'
import {messaging} from "./../rio-proto.js";

const wsAPI = axios.create({
	baseURL:
		process!.env!.VUE_APP_API_HTTP_PROTO! +
		process!.env!.VUE_APP_SESSIONS_URL!,
	withCredentials: true,
	responseType: "arraybuffer",
	headers: {"Content-Type": "application/octet-stream"},
	timeout: 5000,
});

const jsonAPI = axios.create({
	baseURL:
		process!.env!.VUE_APP_API_HTTP_PROTO! +
		process!.env!.VUE_APP_SESSIONS_URL!,
	withCredentials: true,
	responseType: "json",
	timeout: 5000,
});

export class GameStats {
	activePlayers: number;
	activeSessions: number;

	constructor(activePlayers: number, activeSessions: number) {
		this.activePlayers = activePlayers;
		this.activeSessions = activeSessions;
	}
}

export class RestError {
	title: string;
	message: string;

	constructor(title: string, message: string) {
		this.title = title;
		this.message = message;
	}
}

export async function getGameStats(
): Promise<GameStats> {
	try {
		const response = await wsAPI.get("/stats", {responseType: "json"});
		if (response.data?.activePlayers && response.data?.activeSessions) {
		return new GameStats(response.data.activePlayers, response.data.activeSessions);
		}
		return new GameStats(0, 0);
	} catch (err) {
		switch (err) {
			default:
				throw `Error retrieving game stats: ` + err;
		}
	}
}

export function getAllCardsFromDeck(cardDeck: messaging.CardDeckType): messaging.CardDeckCards[] {
	switch (cardDeck) {
		case messaging.CardDeckType.DEFAULT:
			return [messaging.CardDeckCards.DEFAULT_ZERO, messaging.CardDeckCards.DEFAULT_PFIVE, messaging.CardDeckCards.DEFAULT_ONE, messaging.CardDeckCards.DEFAULT_TWO, messaging.CardDeckCards.DEFAULT_THREE, messaging.CardDeckCards.DEFAULT_FIVE, messaging.CardDeckCards.DEFAULT_EIGHT, messaging.CardDeckCards.DEFAULT_THIRTEEN, messaging.CardDeckCards.DEFAULT_TWENTY, messaging.CardDeckCards.DEFAULT_FOURTY, messaging.CardDeckCards.DEFAULT_HUNDRED];
		case messaging.CardDeckType.TSHIRT:
			return [messaging.CardDeckCards.TSHIRT_S, messaging.CardDeckCards.TSHIRT_M, messaging.CardDeckCards.TSHIRT_L, messaging.CardDeckCards.TSHIRT_XL];
	}
	return [];
}

export async function hostGame(
	gameTitle: string,
	cardDeck: messaging.CardDeckType,
): Promise<messaging.Container> {
	try {
		const payload = {openGame: {gameTitle: gameTitle, cardDeck: cardDeck}}

		const response = await wsAPI.post("/newgame",
			payload,
			{transformRequest, transformResponse}
		);
		return response.data;
	} catch (err) {
		switch (err) {
			default:
				throw `Error hosting game: ` + err;
		}
	}
}

export async function getToken(
	gameID: string,
	userID: string,
): Promise<messaging.Container> {
	try {
		const payload = {userToken: {userID: userID}}

		const response = await jsonAPI.post("/token/create/" + gameID,
			payload,
			{transformRequest, transformResponse}
		);
		return response.data;
	} catch (error) {
		const errMsg = error.response.request.response.error
		if (errMsg) {
			throw new RestError("Error creating token", errMsg);
		}else{
			throw new RestError("Error creating token", errMsg);
		}
	}
}

function transformRequest(data: any) {
	const err = messaging.Container.verify(data);

	if (err) {
		throw Error(err!);
	}
	return messaging.Container.encode(data).finish();
}

function transformResponse(data: any): messaging.Container {
	const buf = protobuf.util.newBuffer(data)
	const decoded = messaging.Container.decode(buf)

	return decoded
}
