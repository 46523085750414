








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class SelectMenu extends Vue {
  @Prop() private data!: string[];
  @Prop() private value!: string;
  private isOpen = false;

  isSelected(value: string): boolean {
    return this.value === value;
  }

  closeDropdown(): void {
    this.isOpen = false;
  }

  openDropdown(): void {
    this.isOpen = true;
  }

  select(value: string): void {
    this.isOpen = false;
    this.$emit("valueSelect", value);
  }
}
